


































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';

import AddCustomInvestorGoalWizardLinkGoalViewModel from
  '@/vue-app/view-models/components/goals-dashboard/add-goals/link/add-custom-investor-goal-wizard-link-goal-view-model';

// Domain
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';

@Component({ name: 'LinkGoal' })
export default class LinkGoal extends Vue {
  @PropSync('customGoalSelected', { type: Object, required: true })
  custom_investor_goal_dto!: CustomInvestorGoalDto;

  link_goal_model = Vue.observable(
    new AddCustomInvestorGoalWizardLinkGoalViewModel(this),
  );

  mounted() {
    this.link_goal_model.initialize(this.custom_investor_goal_dto);
  }
}
