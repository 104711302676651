import Vue from 'vue';
import TYPES from '@/types';

// Domain
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AddCustomInvestorGoalWizardLinkGoalViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.add-goals.add-custom-investor-goal-wizard-link-goal';

  readonly view: Vue;

  title = '';

  description = '';

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setCustomGoalSelected = (custom_investor_goal_dto: CustomInvestorGoalDto) => {
    const custom_goal_value = custom_investor_goal_dto.custom_goal_type;

    this.view.$emit('setCustomGoalSelected', custom_goal_value);
  }

  nextStep = async () => {
    this.view.$emit('nextStep');
  }

  loadInformationFromProduct(custom_investor_goal_dto: CustomInvestorGoalDto) {
    const { goal_name } = custom_investor_goal_dto;
    if (custom_investor_goal_dto.fixed_time_adjusted
      && parseFloat(custom_investor_goal_dto.fixed_time_adjusted) >= 12) {
      this.title = this.translate('title', {
        goal_name,
        product_name: 'SOWOS Wealth',
      });
      this.description = this.translate('description_wealth');
    } else {
      this.title = this.translate('title', {
        goal_name,
        product_name: 'SOWOS Pocket',
      });
      this.description = this.translate('description_pocket');
    }
  }

  initialize = async (custom_investor_goal_dto: CustomInvestorGoalDto) => {
    this.loadInformationFromProduct(custom_investor_goal_dto);
    this.setCustomGoalSelected(custom_investor_goal_dto);
  }
}
